.ds-content__title {
  margin-bottom: 6rem;
}

.ds-content__section-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.ds-content__section {
  position: relative;
  max-width: 770px;
  margin-bottom: 8rem;
}

.ds-content__section-title {
  margin-bottom: 4rem;
}

.ds-color__item {
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
}

.ds-color__example {
  position: relative;
  width: 8rem;
  height: 8rem;
  border-radius: 4rem;
  border: 1px solid var(--primary-color);
  margin-right: 2rem;
}

.ds-color__example::after {
  position: absolute;
  content: '';
  left: 4px;
  top: 4px;
  right: 4px;
  bottom: 4px;
  border-radius: 100%;
  border: 1px solid var(--primary-color)
}

.ds-color__example--4444EC::after {
  background-color: var(--primary-color);
}

.ds-color__example--F4EBDF::after {
  background-color: var(--secondary-color);
}

.ds-tints__item {
  text-align: center;
  margin-bottom: 1rem;
}

.ds-tints__item-sample {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
}

.ds-tints__item-title {
  text-align: left;
  margin-bottom: 1rem;
}

.ds-tints__item-color {
  flex: 1;
  height: 5rem;
  margin-right: 1px;
}

.ds-tints__item-color:last-child {
  margin-right: 0px;
}

.ds-space__item {
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
}

.ds-space__item--grid .ds-space__info {
  display: flex;
}

.ds-space__item--grid .ds-space__title {
  width: fit-content;
  margin-right: 4rem;
}

.ds-space__info {
  width: 200px;
}

.ds-space__title {
  width: 140px;
}

.ds-space__example {
  background-color: var(--primary-color-translucid);
  border: 1px solid var(--primary-color-light);
  border-radius: 2px;
}

.ds-space__example--1 {
  width: 1rem;
  height: 1rem;
}

.ds-space__example--2 {
  width: 2rem;
  height: 2rem;
}

.ds-space__example--3 {
  width: 3rem;
  height: 3rem;
}

.ds-space__example--4 {
  width: 4rem;
  height: 4rem;
}

.ds-space__example--8 {
  width: 8rem;
  height: 8rem;
}

.ds-space__example--16 {
  width: 16rem;
  height: 16rem;
}

.ds-font__item {
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
}

.ds-font__example {
  display: flex;
  align-items: baseline;
  margin-right: 4rem;
}

.ds-font__example-primary--regular,
.ds-font__example-primary--bold {
  font-size: 6rem;
  font-family: var(--primary-font);
}

.ds-font__example-primary--bold {
  font-weight: bold;
  margin-right: 2rem;
}

.ds-font__example-secondary--regular,
.ds-font__example-secondary--bold {
  font-size: 6rem;
  font-family: var(--secondary-font);
}

.ds-font__example-secondary--bold {
  font-weight: bold;
  margin-right: 2rem;
}

.ds-font__info {
  display: flex;
  flex-direction: column;
}

.ds-button,
.typeface {
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
}

.ds-button__info,
.typeface__info {
  min-width: 290px;
}

.typeface__info-description {
  display: none;
}

.typeface__example {
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}

.ds-button p {
  margin-bottom: 2rem;
}

.ds-button__button--hover {
  border-width: 2px;
}

.ds-button__button--pressed {
  background-color: var(--primary-color);
  color: var(--secondary-color);
}

.ds-button__button--focus {
  border-width: 2px;
  border-color: var(--primary-color-light);
}

@media (min-width: 768px) {
  .ds-color__group {
    display: flex;
    margin-bottom: 5rem;
  }

  .ds-color__item {
    width: 50%;
    margin-bottom: 0;
  }

  .ds-color__example {
    width: 16rem;
    height: 16rem;
    border-radius: 8rem;
  }

  .ds-color__example::after {
    left: 1rem;
    top: 1rem;
    right: 1rem;
    bottom: 1rem;
  }

  .ds-space__info {
    display: flex;
    width: 400px;
  }

  .ds-font__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
    margin-bottom: 0;
  }

  .ds-font__example {
    display: flex;
    align-items: baseline;
    margin-bottom: 2rem;
  }

  .ds-font__example-primary--regular,
  .ds-font__example-primary--bold {
    font-size: 8rem;
    font-family: var(--primary-font);
  }

  .ds-font__example-primary--bold {
    font-weight: bold;
    margin-right: 2rem;
  }

  .ds-font__example-secondary--regular,
  .ds-font__example-secondary--bold {
    font-size: 8rem;
    font-family: var(--secondary-font);
    line-height: 1em;
  }

  .ds-font__example-secondary--bold {
    font-weight: bold;
    margin-right: 2rem;
  }

  .ds-font__info {
    display: flex;
    flex-direction: column;
  }

  .ds-font__group {
    display: flex;
    margin-bottom: 6rem;
  }

  .ds-button,
  .typeface {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 6rem;
  }

  .typeface__info-description {
    display: block;
  }

  .ds-button p {
    margin-bottom: 0;
  }
}