.page-layout {
  display: flex;
  flex-direction: column;
  color: var(--primary-color);
  background-color: var(--secondary-color);
  min-height: 100vh;
  max-width: 1440px;
  margin: 0 auto;
}

.page-layout--light {
  color: var(--primary-color);
  background-color: var(--secondary-color);
}

.page-layout--dark {
  color: var(--secondary-color);
  background-color: var(--primary-color);
}

.page-layout__content {
  max-width: 1184px;
  width: 100%;
  min-height: calc(100vh - 16rem - 82px - 48px);
  margin:  16rem auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.footer_name_full {
  cursor: default;
}

.footer_name_abbr {
  text-decoration: underline;
  cursor: default;
}

@media (min-width: 768px) {
  .page-layout__content {
    width: calc(100% - 4rem);
  }
}

@media (min-width: 992px) {
  .page-layout__content {
    margin:  24rem auto;
    box-sizing: content-box;
  }
}
