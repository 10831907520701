.mobile-menu {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: var(--secondary-color);
  color: var(--primary-color);
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 9;
}

.mobile-menu--visible {
  display: flex;
  flex-direction: column;
}

.mobile-menu a {
  color: var(--primary-color);
  font-family: var(--secondary-font);
  font-size: 3rem;
  text-decoration: none;
}

.mobile-menu ul {
  padding: 0;
  margin: 0;
}

.mobile-menu li {
  list-style-type: none;
  margin-bottom: 8rem;
}

.mobile-menu__close-button {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  border: 0;
  padding: 0;
  width: 4rem;
  height: 4rem;
  transform: rotate(45deg);
  background-color: transparent;
}

.mobile-menu__close-button::after {
  position: absolute;
  content: "";
  top: 0;
  width: 2px;
  height: 100%;
  background-color: var(--primary-color);
  transform: translateX(-50%);
}

.mobile-menu__close-button::before {
  position: absolute;
  content: "";
  top: 50%;
  width: 100%;
  height: 2px;
  background-color: var(--primary-color);
  transform: translate(-50%, -50%);
}

.menu {
  display: none;
  margin-left: auto;
}

.menu li {
  display: inline-block;
  list-style-type: none;
  margin-right: 4rem;
}

.menu a {
  position: relative;
  text-decoration: none;
}

.menu a::after {
  position: absolute;
  content: '';
  left: 0;
  opacity: 0;
  bottom: -.5rem;
  height: .1em;
  background-color: currentColor;
  width: 100%;
  transform-origin: 0 50%;
  transform: scaleX(0);
  transition: all 0.25s ease-out;
}

.menu a:hover::after,
a[aria-current="page"]::after {
  transform: scaleX(1);
  opacity: 1;
}

.mobile-menu .language-selector__menu {
  display: flex;
  flex-direction: column;
  bottom: 0;
  top: unset;
  left: unset;
  transform: none;
  width: 100%;
  background-color: var(--secondary-color);
}

.mobile-menu .language-selector__menu-item {
  padding: 1rem;
}

.language-selector {
  position: relative;
  display: inline-block;
}

.language-selector__button {
  position: relative;
  border: 0;
  padding: 0;
  height: fit-content;
  background-color: transparent;
  color: currentColor;
  cursor: pointer;
}

.language-selector__button[aria-expanded="true"]::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 3px;
  width: 100%;
  height: 2px;
  background-color: var(--secondary-color);
}

.language-selector__button:focus {
  outline: none;
  background: none;
}

.language-selector__title {
  margin-right: 1rem;
}

.language-selector__menu {
  position: absolute;
  top: 100%;
  transform: translateX(-50%);
  background-color: white;
  padding: .5rem 0;
}

.language-selector__menu-item {
  background-color: transparent;
  color: currentColor;
  font-family: var(--secondary-font);
  color: var(--primary-color);
  border: 0;
  padding: 0;
  font-size: 3rem;
  line-height: 4rem;
  cursor: pointer;
}

.language-selector__menu-item--selected {
  background-color: #DFD8CC;
  text-decoration: underline;
}

.language-selector__menu-item {
  outline: none;
}

.hamburguer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: flex-start;
  width: 3rem;
  height: 18px;
  border: 0;
  background-color: transparent;
  padding: 0;
  color: currentColor;
}

.hamburguer__line {
  position: relative;
  background-color: currentColor;
  width: 3rem;
  height: 2px;
}

@media (min-width: 768px) {
  .hamburguer {
    display: none;
  }

  .menu {
    display: flex;
    align-items: center;
    height: 64px;
  }
}

@media (min-width: 992px) {
  .language-selector__menu {
    left: 50%;
  }
}
