.ui-logo--dark {
  color: var(--primary-color);
  fill: var(--primary-color);
}

.ui-logo--light {
  color: var(--secondary-color);
  fill: var(--secondary-color);
}

.header svg.ui-logo {
  width: 48px;
  height: 48px;
}

@media (min-width: 768px) {
  .header svg.ui-logo {
    width: 64px;
    height: 64px;
  }
}
