.hover-image {
  position: relative;
  cursor: default;
  white-space: nowrap;
}

.hover-image__image {
  display: none;
}

.hover-image:hover .hover-image__image {
  display: block;
  position: absolute;
  width: 400px;
  max-width: calc(100vw - 4rem);
  background-color: red;
  top: 100%;
}

@media (min-width: 768px) {
  .hover-image:hover .hover-image__image {
    left: 0;
  }
}
