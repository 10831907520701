.badge__wrapper {
  display: none;
  position: fixed;
  left: 50%;
  width: 100%;
  max-width: 1312px;
  transform: translateX(-50%);
  bottom: 0;
  z-index: 1;
}

.badge {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 4rem;
  right: 2rem;
  height: 17rem;
  width: 17rem;
  color: currentColor;
  border: 2px solid currentColor;
  border-radius: 8.5rem;
  animation: spin 6s linear infinite;
  text-align: center;
  font-size: 3rem;
  line-height: 4rem;
  font-family: var(--secondary-font);
  cursor: pointer;
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform:rotate(360deg);
  }
}

@media (min-width: 768px) {
  .badge__wrapper {
    display: block;
  }
}