.ui-link {
  position: relative;
  color: currentColor;
  text-decoration: none;
  width: fit-content;
  white-space: nowrap;
}

.ui-link::after {
  position: absolute;
  content: '';
  left: 0;
  bottom: 0;
  height: .1em;
  background-color: currentColor;
  width: 100%;
  transform-origin: 100% 50%;
  transform: scaleX(1);
  transition: all 0.25s ease-out;
}

.ui-link:hover::after {
  animation-name: underline;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
}

@keyframes underline {
  0%   {
    transform-origin: 100% 50%;
    transform: scaleX(1);
  }
  49%  {
    transform: scaleX(0);
    transform-origin: 100% 50%;
  }
  50%  {
    transform: scaleX(0);
    transform-origin: 0% 50%;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 0% 50%;
  }
}

.ui-text {
  font-family: var(--secondary-font);
  margin: 0;
  font-weight: normal;
}

.ui-text--small {
  font-size: 11px;
  line-height: 2rem;
}

.ui-text--regular {
  font-size: 2rem;
  line-height: 3rem;
}

.ui-text--dark {
  color: var(--primary-color);
}

.ui-text--light {
  color: var(--secondary-color);
}

@media (min-width: 768px) {
  .ui-text--small {
    font-size: 12px;
    line-height: 20px;
  }

  .ui-text--regular {
    font-size: 20px;
    line-height: 28px;
  }
}