.header {
  position: fixed;
  width: 100%;
  z-index: 9;
}

.header-content {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  width: 100%;
  max-width: 1312px;
}

.header .hamburguer {
  margin-left: auto;
}

.header a {
  color: currentColor;
}

.header-logo {
  display: flex;
}

@media (min-width: 768px) {
  .header-content {
    display: flex;
    padding: 0 2rem;
    width: 100%;
    max-width: 1312px;
  }

  .header-logo {
    margin-top: 2rem;
  }
}

@media (min-width: 992px) {
  .header-content {
    margin:  5rem auto 0;
  }
}