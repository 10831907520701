.cursor {
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  border: 1px solid currentColor;
  pointer-events: none;
  opacity: .6;
  transition: height .3s, width .3s, border-radius .3s, left .3s, top .3s;
  left: -14px;
  top: -14px;
  z-index: 9999;
}

.cursor--hover {
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  left: -30px;
  top: -30px;
  pointer-events: none;
  opacity: 1;
}


@media (max-width: 600px) {
  .cursor {
    display: none;
  }
}

/* .cursor {
  position: absolute;
  width: 14px;
  height: 14px;
  background-color: currentColor;
  pointer-events: none;
  transition: height .3s, width .3s, border-radius .3s;
  mix-blend-mode: difference;
  z-index: 9999;
}

.cursor--hover {
  position: absolute;
  width: 4rem;
  height: 4rem;
  border-radius: 2rem;
  background-color: currentColor;
  pointer-events: none;
  mix-blend-mode: difference;
}


@media (max-width: 600px) {
  .cursor {
    display: none;
  }
} */