.about-content__subtitle {
  margin-bottom: 6rem;
}

.about-content__description {
  margin-bottom: 4rem;
}

.about-content__download-button,
.about-content .contact-content__email-link {
  margin: 4rem 0 0;
}

.about-content .contact-content__email-link,
.resume-section {
  margin-bottom: 4rem;
}

@media (min-width: 768px) {
  .description-section {
    max-width: 770px;
    margin-bottom: 128px;
  }

  .about-content .contact-content__email-link,
  .resume-section {
    margin-bottom: 8rem;
  }
}