.ui-link {
  color: var(--primary-color);
}

.ui-text {
  font-family: var(--secondary-font);
  color: currentColor;
  margin: 0;
  font-weight: normal;
}

.ui-text--small {
  font-size: 11px;
  line-height: 2rem;
}

.ui-text--regular {
  font-size: 2rem;
  line-height: 3rem;
}

.ui-text--dark {
  color: var(--primary-color);
}

.ui-text--light {
  color: var(--secondary-color);
}

@media (min-width: 768px) {
  .ui-text--small {
    font-size: 1.5rem;
    line-height: 2.5rem;
  }

  .ui-text--regular {
    font-size: 2.5rem;
    line-height: 3.5rem;
  }
}