.ui-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 6rem;
  font-size: 2rem;
  border: 1px solid currentColor;
  background-color: transparent;
  padding: 0 4rem;
  font-family: var(--secondary-font);
  font-weight: bold;
  text-decoration: none;
  box-sizing: border-box;
  cursor: pointer;
  width: 100%;
}

.ui-button--fit {
  width: fit-content;
}

.ui-button svg:last-child {
  margin-left: 2rem;
}

@media (min-width: 768px) {
  .ui-button {
    height: 8rem;
    font-size: 3rem;
  }
}

.ui-button:focus {
  outline: none;
}

.ui-button--primary {
  color: var(--primary-color);
}

.ui-button--primary:focus {
  color: var(--secondary-color);
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.ui-button--secondary {
  color: var(--secondary-color);
}

.ui-button--secondary:focus {
  color: var(--primary-color);
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}

.ui-button--tertiary {
  border-color: var(--primary-color);
  background-color: var(--primary-color);
  color: var(--secondary-color);
}

.ui-button--tertiary:focus {
  color: var(--primary-color);
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.ui-button--tertiary:focus {
  color: var(--secondary-color);
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.ui-button:hover:not(:focus) {
  /* border-width: 2px; */
  box-shadow: inset 0 0 0 1px currentColor;
}

.keyboard .ui-button:focus {
  color: var(--primary-color);
  background-color: var(--secondary-color);
  border-color: currentColor;
  box-shadow: 0 0 0 2px var(--primary-color-light);
}

.ui-button svg {
  margin-left: 1rem;
}