.ui-title {
  font-family: var(--primary-font);
  font-weight: bold;
  margin: 0;
}

.ui-title--hero {
  font-family: var(--primary-font);
  font-size: 5rem;
  line-height: 6rem;
}

.ui-title--regular {
  font-family: var(--secondary-font);
  font-size: 3rem;
  line-height: 4rem;
}

@media (max-width: 375px) {
  .ui-title--hero {
    font-size: 4rem;
    line-height: 5rem;
  }
}

@media (min-width: 768px) {
  .ui-title--hero {
    font-size: 60px;
    line-height: 68px;
  }

  .ui-title--regular {
    font-size: 28px;
    line-height: 36px;
  }
}