.ui-arrow--right {
  transform: rotate(-90deg);
}

.ui-arrow--left {
  transform: rotate(90deg);
}

.ui-arrow--top {
  transform: rotate(180deg);
}
