.editor-box {
  position: fixed;
  background-color: white;
  width: calc(100% - 4rem);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 3rem;
  z-index: 9;
}

.editor-box__overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0,0,0,0.3);
  pointer-events: none;
}

.editor-box__close-button {
  display: flex;
  background-color: transparent;
  border: 0;
  padding: 0;
  margin-left: auto;
  margin-bottom: 1rem;
}
.editor__actions {
  display: flex;
}

.editor__actions button {
  flex: 1;
}

.editor__actions button:last-child {
  margin-left: 2rem;
}

.ds-font-editor__grid {
  display: grid;
  gap: 3rem;
  grid-template-columns: 1fr;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.ds-font-editor__item {
  display: flex;
  align-items: flex-end;
  cursor: pointer;
}

.ds-font-editor__item input{
  align-self: center;
  opacity: 0;
}

.checkbox {
  position: relative;
  width: 2rem;
  height: 2rem;
  border: 1px solid #D5D4DD;
  border-radius: 2px;
  align-self: center;
}

.ds-font-editor__item input:checked ~ .checkbox {
  border-color: currentColor;
}
.ds-font-editor__item input:checked ~ .checkbox::after {
  content: '';
  position: absolute;
  left: 5px;
  top: 1px;
  width: 4px;
  height: 8px;
  border: solid currentColor;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}

.ds-font-editor__item--selected .ds-font-editor__example {
  border-color: var(--primary-color);
}

/* .ds-font-editor__item:nth-child(2n + 2) {
  margin-left: auto;
} */

.ds-font-editor__aa {
  font-size: 4rem;
  margin: 0;
}

.ds-font-editor__example {
  position: relative;
  margin-left: 2rem;
  margin-right: 2rem;
}

.ds-font-editor__example--graphik .ds-font-editor__aa {
  font-family: 'Graphik';
  font-weight: bold;
}

.ds-font-editor__example--space-mono .ds-font-editor__aa {
  font-family: 'Space Mono';
}

.ds-font-editor__example--bluu-next .ds-font-editor__aa {
  font-family: 'Bluu Next';
  font-weight: bold;
}

.ds-font-editor__example--crisom-text .ds-font-editor__aa {
  font-family: 'Crisom Text';
}

.ds-font-editor__example--salome .ds-font-editor__aa {
  font-family: 'Salome';
  font-weight: bold;
}

.ds-font-editor__example--oswald .ds-font-editor__aa {
  font-family: 'Oswald';
}

.ds-font-editor__example--fira-sans .ds-font-editor__aa {
  font-family: 'Fira Sans';
  font-weight: bold;
}

.ds-font-editor__example--roboto-slab .ds-font-editor__aa {
  font-family: 'Roboto Slab';
}

@media (min-width: 480px) {
  .ds-font-editor__grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 768px) {
  .editor-box {
    position: absolute;
    background-color: white;
    left: unset;
    top: unset;
    transform: unset;
    width: 100%;
    margin-top: 2rem;
    padding: 3rem;
    z-index: 9;
  }

  .editor-box::after {
    content: '';
    position: absolute;
    height: 1.6rem;
    width: 1.6rem;
    background-color: white;
    top: -0.8rem;
    transform: rotate(45deg);
    left: 50%;
  }

  .editor-box__overlay {
    position: fixed;
    background-color: rgba(0,0,0,0);
  }

  .editor__actions {
    display: flex;
    justify-content: flex-end;
  }

  .editor__actions button {
    flex: 0;
  }

  /* .ds-font-editor__item:nth-child(2n + 2) {
    margin-left: unset;
  }

  .ds-font-editor__item:nth-child(3n + 2) {
    margin-left: auto;
    margin-right: auto;
  }

  .ds-font-editor__item:nth-child(3n) {
    margin-left: auto;
  } */

  .ds-font-editor__example {
  }
}