@font-face {
  font-family: 'Graphik';
  src: url('./assets/fonts/Graphik-Regular.otf');
  font-weight: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('./assets/fonts/Graphik-Bold.otf');
  font-weight: 700;
}

@font-face {
  font-family: 'Space Mono';
  src: url('./assets/fonts/SpaceMono-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'Space Mono';
  src: url('./assets/fonts/SpaceMono-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'Bluu Next';
  src: url('./assets/fonts/BluuNext-Bold.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'Bluu Next';
  src: url('./assets/fonts/BluuNext-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'Crisom Text';
  src: url('./assets/fonts/CrimsonText-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'Crisom Text';
  src: url('./assets/fonts/CrimsonText-Bold.ttf');
  font-weight: 700;
}
@font-face {
  font-family: 'Salome';
  src: url('./assets/fonts/Salome-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'Salome';
  src: url('./assets/fonts/Salome-Regular.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'Oswald';
  src: url('./assets/fonts/Oswald-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'Oswald';
  src: url('./assets/fonts/Oswald-Bold.ttf');
  font-weight: 700;
}
@font-face {
  font-family: 'Fira Sans';
  src: url('./assets/fonts/FiraSans-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'Fira Sans';
  src: url('./assets/fonts/FiraSans-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'Roboto Slab';
  src: url('./assets/fonts/RobotoSlab-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'Roboto Slab';
  src: url('./assets/fonts/RobotoSlab-Bold.ttf');
  font-weight: 700;
}

:root {
  font-family: 'Space Mono';
  font-size: 8px;
  --primary-color: #4444EC;
  --primary-color-light: #687EF5;
  --primary-color-translucid: rgba(122,151,248,0.3);
  --secondary-color: #F4EBDF;
  --primary-font: 'Graphik';
  --secondary-font: 'Space Mono';
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--primary-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

mark {
  background-color: transparent;
  color: currentColor;
  font-size: 2rem;
  font-weight: 100;
  font-family: Arial, "Segoe UI Emoji", "Apple Color Emoji", "Noto Color Emoji", "Segoe UI Symbol";
}

a,
a:visited {
  color: currentColor;
}