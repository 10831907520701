.contact-page {
  display: flex;
  flex-direction: column;
  color: var(--secondary-color);
  background-color: var(--primary-color);
  min-height: 100vh;
}

.contact-content {
  max-width: 1054px;
  width: 100%;
  min-height: calc(100vh - 16rem - 82px - 48px);
  margin:  16rem auto;
  padding: 0 2rem;
}

.contact-content__email-link {
  margin: 6rem 0;
  display: flex;
  align-items: center;
}

.contact-content__email-link svg,
.contact-content__email-link img {
  margin-right: 2rem;
}

.contact-content__title-section {
  margin-bottom: 2rem;
}

.social-links__menu {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.social-links__item {
  display: flex;
  align-items: center;
  margin: 3rem 0;
}

.social-links__item svg,
.social-links__item img {
  margin-right: 2rem;
}

@media (min-width: 768px) {
  .contact-content {
    max-width: 1054px;
    width: 100%;
    margin:  24rem auto;
    padding: 2rem;
  }
}