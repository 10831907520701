.works-title {
  margin-bottom: 8rem;
}

.works-section {
  margin-bottom: 8rem;
}

.works-section__company {
  margin-bottom: 3rem;
}

.works-section__title,
.works-section__info,
.works-section__description {
  margin-bottom: 4rem;
}

.works-section__image {
  width: 100%;
}

.work-section__external-link {
  position: relative;
  display: flex;
  align-items: center;
  width: fit-content;
  text-decoration: none;
  /* border-bottom: 2px solid currentColor; */
  color: currentColor;
}

.work-section__external-link::after {
  position: absolute;
  content: '';
  left: 0;
  bottom: 0;
  height: 2px;
  background-color: currentColor;
  width: 100%;
  transform-origin: 100% 50%;
  transform: scaleX(1);
  transition: all 0.25s ease-out;
}

.work-section__external-link:hover::after {
  animation-name: underline;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
}

@keyframes underline {
  0%   {
    transform-origin: 100% 50%;
    transform: scaleX(1);
  }
  49%  {
    transform: scaleX(0);
    transform-origin: 100% 50%;
  }
  50%  {
    transform: scaleX(0);
    transform-origin: 0% 50%;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 0% 50%;
  }
}

.work-section__external-link svg {
  margin-left: 2rem;
  width: 16px;
}

.dribbble-gallery {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  margin: 4rem 0;
}

.dribble-work__link {
  border: 2px solid currentColor;
  padding: 1rem;
  filter: grayscale(1);
  transition: all 1s;
}

.dribble-work__link:hover {
  filter: grayscale(0);
}

.dribble-work__link img {
  width: 100%;
}

@media (min-width: 992px) {
  .works-section {
    display: flex;
    margin-bottom: 16rem;
  }

  .works-section:nth-child(2n + 1) {
    flex-direction: row-reverse;
  }

  .works-section__info,
  .works-section__image {
    width: 50%;
  }

  .works-section__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 6rem;
    margin-bottom: 0;
  }

  .works-section:nth-child(2n + 1) .works-section__info {
    padding-right: 0rem;
    padding-left: 6rem;
  }

  .works-section__title,
  .works-section__description {
    padding-left: 3rem;
  }

  .work-section__external-link svg {
    width: 3rem;
  }

  .dribbble-section {
    display: flex;
    flex-direction: column;
  }

  .dribbble-gallery {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 3rem;
    margin: 8rem 0;
  }
}