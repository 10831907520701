figure {
  width: 100%;
  margin: 0;
}

.work-section {
  padding-top: 8rem;
  padding-bottom: 4rem;
  border-bottom: 1px solid currentColor;
}

.work-section:last-child {
  border: 0;
  padding-bottom: 0;
}

.work-section__company {
  margin-bottom: 3rem;
}

.work-section__info:first-child:not(:last-child) {
  margin-bottom: 5rem;
}

.work-section__info .work-section__details:nth-child(2n + 2) {
  margin-bottom: 2rem;
}

.work-section__info .work-section__description:not(:last-child) {
  margin-bottom: 2rem;
}

.work-section__image:first-child {
  width: 100%;
  margin-bottom: 3rem;
}

.work-section__image img {
  width: 100%;
  margin-bottom: 2rem;
}

.work-section__image figcaption {
  text-align: center;
  filter: grayscale();
}

.work-section__image + .work-section__image {
  margin-top: 3rem;
}

.work-section--column .work-section__title {
  margin-bottom: 3rem;
}

.work-section__list {
  padding: 0;
  padding-left: 6rem;
  margin: 0;
  list-style-type: none;
}

.work-section__list li {
  position: relative;
  margin-bottom: 2rem;
}

.work-section__list li::before {
  content: '·';
  position: absolute;
  left: -3rem;
  font-size: 4rem;
  line-height: 0.5;
}

@media (min-width: 768px) {
  .work-section {
    display: flex;
    padding-top: 16rem;
    padding-bottom: 8rem;
  }

  .work-section--column {
    flex-direction: column;
  }

  .work-section--column .work-section__info{
    flex-direction: row;
    padding: 0;
  }

  .work-section--column .work-section__image {
    width: calc(100% - 4rem);
  }

  .work-section--column .work-section__title {
    width: 35%;
    margin-bottom: 0;
  }

  .work-section--column .work-section__description-content {
    width: 65%;
  }

  .work-section__image {
    width: 65%;
    margin: 0;
  }

  .work-section__image:first-child {
    margin: 0;
  }

  .work-section__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 8rem;
    margin-bottom: 0;
  }

  .work-section__list li::before {
    content: '·';
    position: absolute;
    left: -4rem;
    font-size: 8rem;
    line-height: 0.3;
  }
}