.home-page.page-layout {
  justify-content: space-between;
  height: 100vh;
}

.home-page .page-layout__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
}

.home-logo {
  width: 80px;
  height: 80px;
  margin-bottom: 3rem;
}

.home-content__title {
  max-width: 1054px;
}

.home-content__subtitle {
  max-width: 1054px;
  margin-bottom: 6rem;
}

.home-footer {
  bottom: 0;
  padding: 2rem;
}

@media (min-width: 768px) {
  .home-content__title {
    margin-top: 3rem;
  }

  .home-content__subtitle {
    margin-bottom: 8rem;
  }

  .home-logo {
    width: 128px;
    height: 128px;
    margin-bottom: 8rem;
  }

  .home-footer {
    padding: 4rem 8rem;
  }
}