.work-layout {
  display: flex;
  flex-direction: column;
  color: var(--primary-color);
  background-color: var(--secondary-color);
  min-height: 100vh;
  max-width: 1440px;
  margin: 0 auto;
}

.work-layout--light {
  color: var(--primary-color);
  background-color: var(--secondary-color);
}

.work-layout--dark {
  color: var(--secondary-color);
  background-color: var(--primary-color);
}

.work-layout__content {
  max-width: 1184px;
  width: 100%;
  min-height: calc(100vh - 16rem - 82px - 48px);
  margin:  16rem auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.work-footer {
  background-color: var(--primary-color);
  color: var(--secondary-color);
  bottom: 0;
  padding: 2rem;
  padding-top: 8rem;
}

.work-footer__title {
  margin-bottom: 4rem;
}

.work-footer__email-link {
  display: flex;
  align-items: center;
  margin-bottom: 8rem;
}

.work-footer__email-link svg{
  margin-right: 2rem;
}

@media (min-width: 768px) {
  .work-layout__content {
    margin:  24rem auto 16rem;
  }

  .work-footer {
    padding: 4rem;
    padding-top: 16rem;
  }

  .work-footer__title {
    margin-left: 8rem;
  }

  .work-footer__email-link {
    margin-left: 8rem;
    margin-bottom: 16rem;
  }

  .work-footer__email-link svg{
    margin-right: 2rem;
  }
}
