.editor-box {
  position: fixed;
  background-color: white;
  width: calc(100% - 4rem);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 3rem;
  z-index: 9;
}

.editor-box__overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0,0,0,0.3);
  pointer-events: none;
}

.editor-box__close-button {
  display: flex;
  background-color: transparent;
  border: 0;
  padding: 0;
  margin-left: auto;
  margin-bottom: 1rem;
}
.editor__actions {
  display: flex;
}

.editor__actions button {
  flex: 1;
}

.editor__actions button:last-child {
  margin-left: 2rem;
}

.ds-color-editor__grid {
  display: grid;
  gap: 3rem;
  grid-template-columns: 1fr;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.ds-color-editor__item {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.ds-color-editor__item input{
  align-self: center;
  opacity: 0;
}

.checkbox {
  position: relative;
  width: 2rem;
  height: 2rem;
  border: 1px solid #D5D4DD;
  border-radius: 2px;
  align-self: center;
}

.ds-color-editor__item input:checked ~ .checkbox {
  border-color: currentColor;
}
.ds-color-editor__item input:checked ~ .checkbox::after {
  content: '';
  position: absolute;
  left: 5px;
  top: 1px;
  width: 4px;
  height: 8px;
  border: solid currentColor;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}

.ds-color-editor__item--selected .ds-color-editor__example {
  border-color: var(--primary-color);
}

.ds-color-editor__example {
  position: relative;
  width: 6rem;
  height: 6rem;
  border-radius: 4rem;
  border: 1px solid #D5D4DD;
  margin-left: 1rem;
}

.ds-color-editor__example::after {
  position: absolute;
  content: '';
  left: 3px;
  top: 3px;
  right: 3px;
  bottom: 3px;
  border-radius: 100%;
  border: 1px solid #D5D4DD
}

.ds-color-editor__example--4444EC::after {
  background-color: #4444EC;
}

.ds-color-editor__example--F4EBDF::after {
  background-color: #F4EBDF;
}

.ds-color-editor__example--DE0425::after {
  background-color: #DE0425;
}

.ds-color-editor__example--312E39::after {
  background-color: #312E39;
}

.ds-color-editor__example--F2F2F2::after {
  background-color: #F2F2F2;
}

.ds-color-editor__example--237E18::after {
  background-color: #237E18;
}

.ds-color-editor__example--EBF6BE::after {
  background-color: #EBF6BE;
}

.ds-color-editor__example--6402A2::after {
  background-color: #6402A2;
}

.ds-color-editor__example--D4BBF3::after {
  background-color: #D4BBF3;
}

.ds-color-editor__example--0101B9::after {
  background-color: #0101B9;
}

.ds-color-editor__example--B0DEF8::after {
  background-color: #B0DEF8;
}

@media (min-width: 480px) {
  .ds-color-editor__grid {
    grid-template-columns: 1fr 1fr;
  }

  .ds-color-editor__item:nth-child(2n + 2) {
    margin-left: auto;
  }
}

@media (min-width: 768px) {
  .editor-box {
    position: absolute;
    background-color: white;
    left: unset;
    top: unset;
    transform: unset;
    width: 100%;
    margin-top: 2rem;
    padding: 3rem;
    z-index: 9;
  }

  .editor-box::after {
    content: '';
    position: absolute;
    height: 1.6rem;
    width: 1.6rem;
    background-color: white;
    top: -0.8rem;
    transform: rotate(45deg);
    left: 50%;
  }

  .editor-box__overlay {
    position: fixed;
    background-color: rgba(0,0,0,0);
  }

  .editor__actions {
    display: flex;
    justify-content: flex-end;
  }

  .editor__actions button {
    flex: 0;
  }

  .ds-color-editor__grid {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .ds-color-editor__item:nth-child(2n + 2) {
    margin-left: unset;
  }

  .ds-color-editor__item:nth-child(3n + 2) {
    margin-left: auto;
    margin-right: auto;
  }

  .ds-color-editor__item:nth-child(3n) {
    margin-left: auto;
  }

  .ds-color-editor__example {
    width: 7rem;
    height: 7rem;
  }

  .ds-color-editor__example::after {
    left: 4px;
    top: 4px;
    right: 4px;
    bottom: 4px;
  }
}